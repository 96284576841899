<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from '@/pages/Home.vue';

export default {
  name: 'App',
  components: {
    Home
  }
};
</script>

<style>
html,
body {
  overflow-x: hidden;
}
</style>
