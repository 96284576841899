<template>
    <footer>
        <div class="container">
            <img src="@/assets/logo.svg" alt="Wincard Logo" class="logo" />
            <p>{{ $t('allRightsReserved') }}</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'AppFooter',
};
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    overflow-x: hidden;

}

footer {
    color: white;
    padding: 69px 0;
    text-align: center;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 129px;
    margin-bottom: 10px;
    margin-left: -24px;
}

p {
    font-family: Arial;
    font-size: 14px;
    margin: 0;
    color: #000000;
    font-weight: 700;
}

/* CSS for Moto G54 screen resolution (até 1080x2400) */
@media (max-width: 1080px) and (max-height: 2400px) {
    footer {
        color: white;
        padding: 69px 0;
        text-align: center;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 6px;
    }

    .logo {
        width: 124px;
        margin-bottom: 10px;
        margin-left: -42px;
    }

    p {
        font-family: Arial;
        font-size: 8px;
        margin: 0;
        color: #000000;
        margin-left: -27px;
        width: 177px;
        height: 16px;
    }
}

/* Resolução para iphone 14 pro max */
@media (max-width: 430px) {
    footer {
        color: white;
        padding: 69px 0;
        text-align: center;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 6px;
    }

    .logo {
        width: 69px;
        margin-bottom: 10px;
        margin-left: -33px;
    }

    p {
        font-family: Arial;
        font-size: 10px;
        margin: 0;
        color: #000000;
        margin-left: -27px;
        width: 269px;
        height: 68px;
    }
}

/* Resolução para smartphones médios - iPhone 11 Pro Max, iPhone XS Max (até 414px) */
@media (max-width: 414px) {
    footer {
        color: white;
        padding: 69px 0;
        text-align: center;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 6px;
    }

    .logo {
        width: 69px;
        margin-bottom: 10px;
        margin-left: -33px;
    }

    p {
        font-family: Arial;
        font-size: 10px;
        margin: 0;
        color: #000000;
        margin-left: -27px;
        width: 269px;
        height: 68px;
    }
}

/* Resolução para smartphones Android grandes e tablets menores - Galaxy Note (até 412px) */
@media (max-width: 412px) {
    footer {
        color: white;
        padding: 69px 0;
        text-align: center;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 6px;
    }

    .logo {
        width: 69px;
        margin-bottom: 10px;
        margin-left: -33px;
    }

    p {
        font-family: Arial;
        font-size: 10px;
        margin: 0;
        color: #000000;
        margin-left: -27px;
        width: 269px;
        height: 68px;
    }
}

/* Resolução para smartphones maiores - iPhone 12, iPhone 13 (até 390px) */
@media (max-width: 390px) {
    footer {
        color: white;
        padding: 69px 0;
        text-align: center;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 6px;
    }

    .logo {
        width: 69px;
        margin-bottom: 10px;
        margin-left: -33px;
    }

    p {
        font-family: Arial;
        font-size: 10px;
        margin: 0;
        color: #000000;
        margin-left: -27px;
        width: 269px;
        height: 68px;
    }
}

/* Resolução para smartphones pequenos - iPhone SE, iPhone 6/7/8, Android (até 375px) */
@media (max-width: 375px) {
    footer {
        color: white;
        padding: 69px 0;
        text-align: center;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 6px;
    }

    .logo {
        width: 69px;
        margin-bottom: 10px;
        margin-left: -33px;
    }

    p {
        font-family: Arial;
        font-size: 10px;
        margin: 0;
        color: #000000;
        margin-left: -27px;
        width: 269px;
        height: 68px;
    }
}
</style>
