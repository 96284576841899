<template>
    <section class="how-it-works">
        <div class="container">
            <h2>{{ $t('howItWorks') }}</h2>
            <div class="toggle-buttons">
                <button :class="{ 'personal-button': true, 'active': activeButton === 'personal' }"
                    @click="toggleButton('personal')">
                    {{ $t('personal') }}
                </button>
                <button :class="{ 'business-button': true, 'active': activeButton === 'business' }"
                    @click="toggleButton('business')">
                    {{ $t('business') }}
                </button>
            </div>
            <div v-if="activeButton === 'personal'" class="steps-container">
                <div class="step">
                    <div class="step-circle">1</div>
                    <p>{{ $t('personalStep1') }}</p>
                    <button class="cta-button personal-cta">{{ $t('getYourCard') }}</button>
                </div>
                <div class="step">
                    <div class="step-circle">2</div>
                    <p>{{ $t('personalStep2') }}</p>
                </div>
                <div class="step">
                    <div class="step-circle">3</div>
                    <p>{{ $t('personalStep3') }}</p>
                </div>
            </div>
            <div v-if="activeButton === 'business'" class="steps-container">
                <div class="step">
                    <div class="step-circle">1</div>
                    <p>{{ $t('businessStep1') }}</p>
                    <button class="cta-button business-cta">{{ $t('createAccount') }}</button>
                </div>
                <div class="step">
                    <div class="step-circle">2</div>
                    <p>{{ $t('businessStep2') }}</p>
                </div>
                <div class="step">
                    <div class="step-circle">3</div>
                    <p>{{ $t('businessStep3') }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AppContent',
    data() {
        return {
            activeButton: 'personal',
        };
    },
    methods: {
        toggleButton(button) {
            this.activeButton = button;
        },
    },
};
</script>

<style scoped>
.how-it-works {
    padding: 167px 0;
    background-color: #f7f7f7;
    position: relative;
    margin-top: 31px;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.how-it-works h2 {
    font-size: 27px;
    font-family: 'Josefin Sans', sans-serif;
    margin-bottom: 20px;
    font-weight: bold;
    color: #313645;
    text-align: left;
    position: absolute;
    margin-top: -104px;
}

.toggle-buttons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 9px;
    position: absolute;
    top: 19px;
}

.personal-button,
.business-button {
    background-color: #F7F7F7;
    color: black;
    border: 2px solid #313645;
    padding: 10px 30px;
    cursor: pointer;
    border-radius: 6px;
    font-family: Arial;
    font-size: 18px;
    margin: 0 -8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 164.5px;
    height: 49.8px;
    margin-top: -65px;
    font-weight: 400
}

.personal-button.active,
.business-button.active {
    background-color: #494C54;
    color: white;
}

.personal-button:hover,
.business-button:hover {
    background-color: #494C54;
    color: white;
}


.steps-container {
    display: flex;
    justify-content: space-around;
    margin-top: 87px;
    gap: 44px;
}

.step {
    text-align: center;
    max-width: 250px;
    background-color: #e0e0e0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    width: 309.34px;
    height: 179.19px;
}

.step-circle {
    width: 27px;
    height: 27px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    font-size: 18px;
    margin-top: 28px;
    border: 1px solid #000;
    font-Weight: 700;
    font-family: Arial;
}

.step p {
    font-size: 18px;
    color: #313645;
    margin: 0 15px;
    font-weight: 700;
    font-family: Arial;
}

.cta-button {
    border: none;
    padding: 15px 40px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.personal-cta {
    background-color: #494C54;
    color: #fff;
    margin-right: 28px;
    margin-left: -18px;
    margin-top: 126px;
}

.personal-cta:hover {
    background-color: #575c66;
}

.business-cta {
    background-color: #494C54;
    color: #fff;
    margin-top: 162px;
    margin-left: -37px;
    margin-right: -8px;
    Width: 258px;
    Height: 47px;
    font-size: 16px;
}

.business-cta:hover {
    background-color: #333;
}

/* CSS for Moto G54 screen resolution (até 1080x2400) */
@media (max-width: 1080px) and (max-height: 2400px) {
    .how-it-works {
        padding: 167px 0;
        background-color: #f7f7f7;
        position: relative;
        margin-top: -38px;
    }

    .container {
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }

    .how-it-works h2 {
        font-size: 27px;
        font-family: 'Josefin Sans', sans-serif;
        margin-bottom: 20px;
        font-weight: bold;
        color: #313645;
        text-align: left;
        position: absolute;
        margin-top: -104px;
        margin-left: 20px;
    }

    .toggle-buttons {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        margin-left: 9px;
        position: absolute;
        top: 19px;
    }

    .personal-button,
    .business-button {
        background-color: #F7F7F7;
        color: black;
        border: 2px solid #313645;
        padding: 10px 30px;
        cursor: pointer;
        border-radius: 6px;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 18px;
        margin: 0 -8px;
        transition: background-color 0.3s ease, color 0.3s ease;
        width: 164.5px;
        height: 49.8px;
        margin-top: -65px;
        margin-left: 16px;
        margin-right: -30px;
    }

    .personal-button.active,
    .business-button.active {
        background-color: #494C54;
        color: white;
    }

    .personal-button:hover,
    .business-button:hover {
        background-color: #494C54;
        color: white;
    }


    .steps-container {
        display: flex;
        justify-content: space-around;
        margin-top: 87px;
        gap: 44px;
        flex-direction: column;
        margin-left: 352px;
    }

    .step {
        text-align: center;
        max-width: 401px;
        background-color: #e0e0e0;
        padding: 40px;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #ccc;
        width: 397.34px;
        height: 212.19px;
        margin-left: -80px;
    }

    .step-circle {
        width: 33px;
        height: 33px;
        background-color: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 20px;
        font-size: 30px;
        margin-top: 28px;
        border: 1px solid #000;
        font-Weight: 700;
    }

    .step p {
        font-size: 26px;
        color: #313645;
        margin: 0 15px;
        font-weight: 700;
    }

    .cta-button {
        border: none;
        padding: 15px 40px;
        font-size: 21px;
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        margin-top: 20px;
        position: sticky;
        bottom: 0;
    }

    .personal-cta {
        background-color: #313645;
        color: #fff;
        margin-right: -14px;
        margin-left: -31px;
        margin-top: 811px;
        width: 353px;
        height: 67px;
        position: sticky;
        bottom: 0;
    }

    .personal-cta:hover {
        background-color: #575c66;
    }

    .business-cta {
        background-color: #494C54;
        color: #fff;
        margin-top: 871px;
        margin-left: -22px;
        margin-right: -8px;
        width: 353px;
        height: 67px;
        position: sticky;
        bottom: 0;
    }

    .business-cta:hover {
        background-color: #333;
    }

}

/* Resolução para iphone 14 pro max */
@media (max-width: 430px) {
    .how-it-works {
        padding: 163px 0;
        background-color: #f7f7f7;
        position: relative;
        margin-top: -86px;
        margin-left: 1px;
        margin-right: -6px;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }

    .how-it-works h2 {
        font-size: 12px;
        font-family: 'Josefin Sans', sans-serif;
        margin-bottom: 20px;
        font-weight: bold;
        color: #313645;
        text-align: left;
        position: absolute;
        margin-top: -115px;
        margin-left: 24px;
    }

    .toggle-buttons {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        margin-left: 9px;
        position: absolute;
        top: 19px;
    }

    .personal-button,
    .business-button {
        background-color: #F7F7F7;
        color: black;
        border: 1px solid #313645;
        padding: 0px 3px;
        cursor: pointer;
        border-radius: 3px;
        font-family: Arial;
        font-size: 15px;
        margin: 0 -8px;
        transition: background-color 0.3s ease, color 0.3s ease;
        width: 103.5px;
        height: 28.8px;
        margin-top: -118px;
        margin-left: 16px;
        margin-right: -19px;
    }

    .personal-button.active,
    .business-button.active {
        background-color: #494C54;
        color: white;
    }

    .personal-button:hover,
    .business-button:hover {
        background-color: #494C54;
        color: white;
    }


    .steps-container {
        display: flex;
        justify-content: space-around;
        margin-top: -29px;
        gap: 30px;
        flex-direction: column;
        margin-left: 161px;
    }

    .step {
        text-align: center;
        background-color: #e0e0e0;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #ccc;
        width: 206px;
        height: 93px;
    }

    .step-circle {
        width: 25px;
        height: 25px;
        background-color: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -1px auto 8px;
        font-size: 18px;
        margin-top: -8px;
        border: 1px solid #000;
        font-Weight: 700;
        font-family: Arial;
    }

    .step p {
        font-size: 16px;
        color: #313645;
        margin: 0 15px;
        font-weight: 700;
        font-family: Arial;
    }

    .cta-button {
        border: none;
        padding: 7px 28px;
        font-size: 17px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease;
        margin-top: 20px;
    }

    .personal-cta {
        background-color: #313645;
        color: #fff;
        margin-right: 2px;
        margin-left: -4px;
        margin-top: 412px;
        font-size: 15px;
        width: 217px;
        height: 52px;
        font-family: Arial;
    }

    .personal-cta:hover {
        background-color: #575c66;
    }

    .business-cta {
        background-color: #494C54;
        color: #fff;
        margin-top: 430px;
        margin-left: -6px;
        margin-right: -8px;
        Width: 222px;
        Height: 52px;
        font-size: 15px;
    }

    .business-cta:hover {
        background-color: #333;
    }

}

/* Resolução para smartphones médios - iPhone 11 Pro Max, iPhone XS Max (até 414px) */
@media (max-width: 414px) {
    .how-it-works {
        padding: 163px 0;
        background-color: #f7f7f7;
        position: relative;
        margin-top: -86px;
        margin-left: 1px;
        margin-right: -6px;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }

    .how-it-works h2 {
        font-size: 12px;
        font-family: 'Josefin Sans', sans-serif;
        margin-bottom: 20px;
        font-weight: bold;
        color: #313645;
        text-align: left;
        position: absolute;
        margin-top: -115px;
        margin-left: 11px;
    }

    .toggle-buttons {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        margin-left: 9px;
        position: absolute;
        top: 19px;
    }

    .personal-button,
    .business-button {
        background-color: #F7F7F7;
        color: black;
        border: 1px solid #313645;
        padding: 0px 3px;
        cursor: pointer;
        border-radius: 3px;
        font-family: Arial;
        font-size: 15px;
        margin: 0 -8px;
        transition: background-color 0.3s ease, color 0.3s ease;
        width: 103.5px;
        height: 28.8px;
        margin-top: -118px;
        margin-left: 1px;
        margin-right: -8px;
    }

    .personal-button.active,
    .business-button.active {
        background-color: #494C54;
        color: white;
    }

    .personal-button:hover,
    .business-button:hover {
        background-color: #494C54;
        color: white;
    }


    .steps-container {
        display: flex;
        justify-content: space-around;
        margin-top: -29px;
        gap: 38px;
        flex-direction: column;
        margin-left: 155px;
    }

    .step {
        text-align: center;
        background-color: #e0e0e0;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #ccc;
        width: 206px;
        height: 93px;
    }

    .step-circle {
        width: 25px;
        height: 25px;
        background-color: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -1px auto 8px;
        font-size: 18px;
        margin-top: -8px;
        border: 1px solid #000;
        font-Weight: 700;
        font-family: Arial;
    }

    .step p {
        font-size: 16px;
        color: #313645;
        margin: 0 15px;
        font-weight: 700;
        font-family: Arial;
    }

    .cta-button {
        border: none;
        padding: 7px 28px;
        font-size: 17px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease;
        margin-top: 20px;
    }

    .personal-cta {
        background-color: #313645;
        color: #fff;
        margin-right: 2px;
        margin-left: -6px;
        margin-top: 433px;
        font-size: 15px;
        width: 217px;
        height: 52px;
        font-family: Arial;
    }

    .personal-cta:hover {
        background-color: #575c66;
    }

    .business-cta {
        background-color: #494C54;
        color: #fff;
        margin-top: 451px;
        margin-left: -9px;
        margin-right: -8px;
        Width: 222px;
        Height: 52px;
        font-size: 15px;
    }

    .business-cta:hover {
        background-color: #333;
    }

}

/* Resolução para smartphones Android grandes e tablets menores - Galaxy Note (até 412px) */
@media (max-width: 412px) {
    .how-it-works {
        padding: 163px 0;
        background-color: #f7f7f7;
        position: relative;
        margin-top: -86px;
        margin-left: 1px;
        margin-right: -6px;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }

    .how-it-works h2 {
        font-size: 12px;
        font-family: 'Josefin Sans', sans-serif;
        margin-bottom: 20px;
        font-weight: bold;
        color: #313645;
        text-align: left;
        position: absolute;
        margin-top: -115px;
        margin-left: 11px;
    }

    .toggle-buttons {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        margin-left: 9px;
        position: absolute;
        top: 19px;
    }

    .personal-button,
    .business-button {
        background-color: #F7F7F7;
        color: black;
        border: 1px solid #313645;
        padding: 0px 3px;
        cursor: pointer;
        border-radius: 3px;
        font-family: Arial;
        font-size: 15px;
        margin: 0 -8px;
        transition: background-color 0.3s ease, color 0.3s ease;
        width: 103.5px;
        height: 28.8px;
        margin-top: -118px;
        margin-left: 1px;
        margin-right: -8px;
    }

    .personal-button.active,
    .business-button.active {
        background-color: #494C54;
        color: white;
    }

    .personal-button:hover,
    .business-button:hover {
        background-color: #494C54;
        color: white;
    }


    .steps-container {
        display: flex;
        justify-content: space-around;
        margin-top: -29px;
        gap: 30px;
        flex-direction: column;
        margin-left: 154px;
    }

    .step {
        text-align: center;
        background-color: #e0e0e0;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #ccc;
        width: 206px;
        height: 93px;
    }

    .step-circle {
        width: 25px;
        height: 25px;
        background-color: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -1px auto 8px;
        font-size: 18px;
        margin-top: -8px;
        border: 1px solid #000;
        font-Weight: 700;
        font-family: Arial;
    }

    .step p {
        font-size: 16px;
        color: #313645;
        margin: 0 15px;
        font-weight: 700;
        font-family: Arial;
    }

    .cta-button {
        border: none;
        padding: 7px 28px;
        font-size: 17px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease;
        margin-top: 20px;
    }

    .personal-cta {
        background-color: #313645;
        color: #fff;
        margin-right: 2px;
        margin-left: -4px;
        margin-top: 412px;
        font-size: 15px;
        width: 217px;
        height: 52px;
        font-family: Arial;
    }

    .personal-cta:hover {
        background-color: #575c66;
    }

    .business-cta {
        background-color: #494C54;
        color: #fff;
        margin-top: 430px;
        margin-left: -8px;
        margin-right: -9px;
        Width: 222px;
        Height: 52px;
        font-size: 15px;
    }

    .business-cta:hover {
        background-color: #333;
    }

}

/* Resolução para smartphones maiores - iPhone 12, iPhone 13 (até 390px) */
@media (max-width: 390px) {
    .how-it-works {
        padding: 163px 0;
        background-color: #f7f7f7;
        position: relative;
        margin-top: -86px;
        margin-left: 1px;
        margin-right: -6px;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }

    .how-it-works h2 {
        font-size: 12px;
        font-family: 'Josefin Sans', sans-serif;
        margin-bottom: 20px;
        font-weight: bold;
        color: #313645;
        text-align: left;
        position: absolute;
        margin-top: -115px;
        margin-left: 11px;
    }

    .toggle-buttons {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        margin-left: 9px;
        position: absolute;
        top: 19px;
    }

    .personal-button,
    .business-button {
        background-color: #F7F7F7;
        color: black;
        border: 1px solid #313645;
        padding: 0px 3px;
        cursor: pointer;
        border-radius: 3px;
        font-family: Arial;
        font-size: 15px;
        margin: 0 -8px;
        transition: background-color 0.3s ease, color 0.3s ease;
        width: 103.5px;
        height: 28.8px;
        margin-top: -118px;
        margin-left: 1px;
        margin-right: -8px;
    }

    .personal-button.active,
    .business-button.active {
        background-color: #494C54;
        color: white;
    }

    .personal-button:hover,
    .business-button:hover {
        background-color: #494C54;
        color: white;
    }


    .steps-container {
        display: flex;
        justify-content: space-around;
        margin-top: -29px;
        gap: 30px;
        flex-direction: column;
        margin-left: 145px;
    }

    .step {
        text-align: center;
        background-color: #e0e0e0;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #ccc;
        width: 206px;
        height: 93px;
    }

    .step-circle {
        width: 25px;
        height: 25px;
        background-color: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -1px auto 8px;
        font-size: 18px;
        margin-top: -8px;
        border: 1px solid #000;
        font-Weight: 700;
        font-family: Arial;
    }

    .step p {
        font-size: 16px;
        color: #313645;
        margin: 0 15px;
        font-weight: 700;
        font-family: Arial;
    }

    .cta-button {
        border: none;
        padding: 7px 28px;
        font-size: 17px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease;
        margin-top: 20px;
    }

    .personal-cta {
        background-color: #313645;
        color: #fff;
        margin-right: 2px;
        margin-left: -4px;
        margin-top: 412px;
        font-size: 15px;
        width: 217px;
        height: 52px;
        font-family: Arial;
    }

    .personal-cta:hover {
        background-color: #575c66;
    }

    .business-cta {
        background-color: #494C54;
        color: #fff;
        margin-top: 430px;
        margin-left: -7px;
        margin-right: -8px;
        Width: 222px;
        Height: 52px;
        font-size: 15px;
    }

    .business-cta:hover {
        background-color: #333;
    }

}

/* Resolução para smartphones pequenos - iPhone SE, iPhone 6/7/8, Android (até 375px) */
@media (max-width: 375px) {
    .how-it-works {
        padding: 163px 0;
        background-color: #f7f7f7;
        position: relative;
        margin-top: -86px;
        margin-left: 1px;
        margin-right: -6px;
    }

    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }

    .how-it-works h2 {
        font-size: 12px;
        font-family: 'Josefin Sans', sans-serif;
        margin-bottom: 20px;
        font-weight: bold;
        color: #313645;
        text-align: left;
        position: absolute;
        margin-top: -115px;
        margin-left: 11px;
    }

    .toggle-buttons {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        margin-left: 9px;
        position: absolute;
        top: 19px;
    }

    .personal-button,
    .business-button {
        background-color: #F7F7F7;
        color: black;
        border: 1px solid #313645;
        padding: 0px 3px;
        cursor: pointer;
        border-radius: 3px;
        font-family: Arial;
        font-size: 15px;
        margin: 0 -8px;
        transition: background-color 0.3s ease, color 0.3s ease;
        width: 103.5px;
        height: 28.8px;
        margin-top: -118px;
        margin-left: 1px;
        margin-right: -8px;
    }

    .personal-button.active,
    .business-button.active {
        background-color: #494C54;
        color: white;
    }

    .personal-button:hover,
    .business-button:hover {
        background-color: #494C54;
        color: white;
    }


    .steps-container {
        display: flex;
        justify-content: space-around;
        margin-top: -29px;
        gap: 30px;
        flex-direction: column;
        margin-left: 136px;
    }

    .step {
        text-align: center;
        background-color: #e0e0e0;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #ccc;
        width: 206px;
        height: 93px;
    }

    .step-circle {
        width: 25px;
        height: 25px;
        background-color: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -1px auto 8px;
        font-size: 18px;
        margin-top: -8px;
        border: 1px solid #000;
        font-Weight: 700;
        font-family: Arial;
    }

    .step p {
        font-size: 16px;
        color: #313645;
        margin: 0 15px;
        font-weight: 700;
        font-family: Arial;
    }

    .cta-button {
        border: none;
        padding: 7px 28px;
        font-size: 17px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease;
        margin-top: 20px;
    }

    .personal-cta {
        background-color: #313645;
        color: #fff;
        margin-right: 2px;
        margin-left: -4px;
        margin-top: 400px;
        font-size: 15px;
        width: 217px;
        height: 52px;
        font-family: Arial;
    }

    .personal-cta:hover {
        background-color: #575c66;
    }

    .business-cta {
        background-color: #494C54;
        color: #fff;
        margin-top: 418px;
        margin-left: -15px;
        margin-right: -17px;
        Width: 222px;
        Height: 52px;
        font-size: 15px;
    }

    .business-cta:hover {
        background-color: #333;
    }

}
</style>