import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import axios from 'axios';
import VueGtag from 'vue-gtag';
import en from './locales/en.json';
import es from './locales/es.json';
import pt from './locales/pt.json';

// Configurar Axios para apontar para o backend Laravel
axios.defaults.baseURL = 'http://localhost:8000'; // Defina o URL correto do Laravel backend

// Configurar idiomas
const messages = {
  en,
  es,
  pt
};

// Detectar idioma do dispositivo do usuário
const userLanguage = navigator.language || navigator.userLanguage;
const locale = userLanguage.split('-')[0]; // Pega apenas a parte "en", "es" ou "pt"

const i18n = createI18n({
  legacy: false,
  locale: locale in messages ? locale : 'en', // Define o idioma padrão se não for encontrado
  fallbackLocale: 'en', // Idioma padrão de fallback
  messages,
});

const app = createApp(App);

// Adicionar o axios globalmente para ser acessível em todos os componentes
app.config.globalProperties.$axios = axios;

// Configurar o Google Analytics com vue-gtag
app.use(VueGtag, {
  config: { id: 'G-6V0E8WPGMX' } // Substitua pelo seu ID de Medição do Google Analytics
});

app.use(i18n);
app.mount('#app');
