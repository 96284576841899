<template>
    <header class="header">
        <div class="container">
            <div class="logo-and-content">
                <div class="logo">
                    <img src="@/assets/logo.svg" alt="Wincard Logo" class="logo-img" />
                </div>
                <div class="main-content">
                    <p class="get-started">{{ $t('getStarted') }}</p>
                    <h2>{{ $t('goodbyeMessage') }}</h2>
                    <p class="enjoy-rewards">{{ $t('enjoyRewards') }}</p>
                    <button class="cta-button" @click="getGoogleWalletCard">{{ $t('getYourCard') }}</button>
                </div>
            </div>
            <div class="card-container">
                <nav class="nav">
                    <!-- Adicione o evento @click nos botões para redirecionar -->
                    <button class="login-button" @click="redirectToAccessPage">{{ $t('login') }}</button>
                    <button class="signup-button" @click="redirectToAccessPage">{{ $t('signup') }}</button>
                </nav>
                <div class="confetti">
                    <img src="@/assets/confetti.svg" alt="Confetti Image" class="confetti-item" />
                </div>
                <div class="card-image">
                    <img src="@/assets/card.svg" alt="Card Image" />
                </div>
            </div>
        </div>
    </header>
</template>

<script>

import axios from 'axios';

export default {
    name: 'AppHeader',
    methods: {
        async getGoogleWalletCard() {
            try {
                console.log("Iniciando a criação do cartão");
                const createCardResponse = await axios.post(`/api/user-card`, {
                    userName: 'anonym_user'
                });

                console.log("Resposta da criação do cartão:", createCardResponse);

                if (createCardResponse.status === 201) {
                    const cardId = createCardResponse.data.cardId;
                    console.log("Cartão criado com sucesso. cardId:", cardId);
                    console.log("Dados do cartão criado:", createCardResponse.data); // Log completo dos dados recebidos

                    const response = await axios.post(`/api/generate-google-wallet-token`, {
                        cardId: cardId,
                        userName: 'anonym_user'
                    });

                    console.log("Resposta da geração do token para Google Wallet:", response);

                    if (response.data.jwtToken) {
                        const walletLink = `https://pay.google.com/gp/v/save/${response.data.jwtToken}`;
                        console.log("Link gerado para Google Wallet:", walletLink);
                        window.location.href = walletLink;
                    } else {
                        console.error("Erro: JWT não recebido na resposta da API.");
                        alert('Erro ao gerar o cartão. Tente novamente.');
                    }
                } else {
                    console.error("Erro ao criar o cartão. Status da resposta:", createCardResponse.status);
                    alert('Erro ao criar o cartão. Tente novamente.');
                }
            } catch (error) {
                console.error('Erro ao gerar o cartão:', error);
                alert('Ocorreu um erro ao gerar o cartão.');
            }
        },
        // Função para redirecionar o usuário para a página externa
        redirectToAccessPage() {
            window.location.href = 'http://127.0.0.1:8000';
        }
    }
};

</script>


<style scoped>
.container {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo-and-content {
    flex: 1;
}

.logo {
    margin-bottom: 20px;
    margin-top: 0;
}

.logo-img {
    width: 129px;
    height: 32px;
    margin-top: -50px;
    margin-bottom: 25px;
}

.main-content {
    text-align: left;
    max-width: 600px;
    margin-top: 117px;
}

.get-started {
    font-size: 14px;
    font-weight: bold;
    color: #B0B0B0;
    margin-bottom: 10px;
    font-family: Arial;
    position: relative;
    top: -18px;
}

.enjoy-rewards {
    font-size: 14px;
    margin-top: -22px;
    font-weight: 700;
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 2px;
}

.main-content h2 {
    font-size: 29px;
    font-weight: bold;
    width: 263px;
    height: 96px;
    margin-bottom: 10px;
    font-family: 'Josefin Sans', sans-serif;
    position: relative;
    top: -29px;
}

.cta-button {
    background-color: #494C54;
    color: #fff;
    border: none;
    padding: 9px 8px;
    font-size: 10px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    font-family: arial;
    Width: 105.04px;
    Height: 31.8px;
    font-weight: 400;
}

.cta-button:hover {
    background-color: #333;
}

.card-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
}

.nav {
    position: absolute;
    top: 23px;
    right: 0;
    display: flex;
    gap: 10px;
}

.login-button[data-v-61dd7a3d] {
    background-color: transparent;
    color: #000;
    border: none;
    padding: 0px 0px;
    font-size: 10px;
    cursor: pointer;
    border-radius: 8px;
    font-family: Arial;
    Width: 67px;
    Height: 34px;
    margin-top: -8px;
}

.signup-button {
    background-color: transparent;
    color: #000;
    border: none;
    padding: 0px 1px;
    font-size: 9px;
    cursor: pointer;
    border-radius: 8px;
    font-family: Arial;
    Width: 70.55px;
    Height: 29.54px;
    margin-top: -8px;
}

.signup-button {
    background-color: #000;
    color: #fff;
}

.signup-button:hover {
    background-color: #333;
}

.card-image img {
    width: 231px;
    height: 330px;
    z-index: 2;
    margin-top: 98px;
    margin-left: 19px;
    position: relative;
    margin-right: 78px;
}

.confetti-item {
    position: absolute;
    top: 37px;
    left: -129px;
    width: 478.36px;
    height: 423.96px;
    z-index: 1;
    object-fit: cover;
}

/* CSS for Moto G54 screen resolution (até 1080x2400) */
@media (max-width: 1080px) and (max-height: 2400px) {
    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .logo-and-content {
        flex: 1;
    }

    .logo {
        margin-bottom: 20px;
        margin-top: 9px;
    }

    .logo-img {
        margin-top: -43px;
        margin-left: 20px;
        width: 150px;
        height: 40px;
        margin-bottom: 153px;
    }

    .main-content {
        text-align: left;
        max-width: 100%;
        margin-top: 266px;
        margin-left: -9px;
    }

    .get-started {
        font-size: 10px;
        font-weight: bold;
        color: #B0B0B0;
        margin-bottom: 10px;
        font-family: Arial;
        position: relative;
        top: -215px;
    }

    .enjoy-rewards {
        font-size: 14px;
        margin-top: -6px;
        font-weight: 700;
        font-family: 'Josefin Sans', sans-serif;
    }

    .main-content h2 {
        font-size: 52px;
        font-weight: bold;
        width: 478px;
        height: 47px;
        margin-bottom: 1px;
        font-family: 'Josefin Sans', sans-serif;
        position: relative;
        top: -239px;
        margin-left: 31px;
    }

    .main-content p {
        font-size: 20px;
        margin-top: -81px;
        font-Weight: 700;
        margin-left: 31px;
        margin-bottom: 3px;
    }

    .cta-button {
        background-color: #494C54;
        color: #fff;
        border: none;
        padding: 5px 10px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        margin-left: 30px;
        font-family: Arial;
        margin-top: 15px;
        Width: 104.04px;
        Height: 25.8px;
        font-weight: 400;
    }

    .cta-button:hover {
        background-color: #333;
    }

    .card-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 50px;
    }

    .nav {
        position: absolute;
        top: 21px;
        right: 28px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        margin-left: -18px;
    }

    .login-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 2px 7px;
        font-size: 14px;
        cursor: pointer;
        border-radius: 5px;
        margin-left: -47px;
        font-family: Arial;
    }

    .signup-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 6px 5px;
        font-size: 11px;
        cursor: pointer;
        border-radius: 8px;
        font-family: Arial;
    }

    .signup-button {
        background-color: #000;
        color: #fff;
    }

    .signup-button:hover {
        background-color: #333;
    }

    .card-image img {
        width: 290px;
        height: 310px;
        z-index: 2;
        margin-top: 174px;
        margin-left: -151px;
        margin-right: -16px;
        margin-bottom: 78px;
    }

    .confetti-item {
        position: absolute;
        top: 128px;
        left: -285px;
        width: 408px;
        height: 359px;
        z-index: 1;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

/* Resolução para iphone 14 pro max */
@media (max-width: 430px) {
    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .logo-and-content {
        flex: 1;
    }

    .logo {
        margin-bottom: 20px;
        margin-top: 9px;
    }

    .logo-img {
        margin-top: -32px;
        margin-left: 19px;
        width: 103px;
        height: 32px;
        margin-bottom: 222px;
    }

    .main-content {
        text-align: left;
        max-width: 100%;
        margin-top: 266px;
        margin-left: -9px;
    }

    .get-started {
        font-size: 10px;
        font-weight: bold;
        color: #B0B0B0;
        margin-bottom: 10px;
        font-family: Arial;
        position: relative;
        top: -226px;
    }

    .enjoy-rewards {
        font-size: 14px;
        margin-top: -6px;
        font-weight: 700;
        font-family: 'Josefin Sans', sans-serif;
    }

    .main-content h2 {
        font-size: 17px;
        font-weight: bold;
        width: 178px;
        height: 49px;
        margin-bottom: 1px;
        font-family: 'Josefin Sans', sans-serif;
        position: relative;
        top: -233px;
        margin-left: 31px;
    }

    .main-content p {
        font-size: 10px;
        margin-top: -215px;
        font-Weight: 700;
        margin-left: 31px;
        margin-bottom: 3px;
    }

    .cta-button {
        background-color: #494C54;
        color: #fff;
        border: none;
        padding: 5px 10px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        margin-left: 30px;
        font-family: Arial;
        margin-top: 9px;
    }

    .cta-button:hover {
        background-color: #333;
    }

    .card-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 50px;
    }

    .nav {
        position: absolute;
        top: 5px;
        right: 17px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        margin-left: -18px;
        Width: 141.55px;
        Height: 29.54px;
    }

    .login-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 2px 7px;
        font-size: 12px;
        cursor: pointer;
        border-radius: 5px;
        margin-left: -47px;
        font-family: Arial;
        margin-top: -6px;
    }

    .signup-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 4px 4px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 8px;
        font-family: Arial;
        Width: 67.55px;
        Height: 21.54px;
    }

    .signup-button {
        background-color: #000;
        color: #fff;
    }

    .signup-button:hover {
        background-color: #333;
    }

    .card-image img {
        width: 86px;
        height: 215px;
        z-index: 2;
        margin-top: 39px;
        margin-left: -15px;
        margin-right: 13px;
        margin-bottom: 78px;
    }


    .confetti-item {
        position: absolute;
        top: 54px;
        left: -87px;
        width: 168px;
        height: 155px;
        z-index: 1;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

/* Resolução para smartphones médios - iPhone 11 Pro Max, iPhone XS Max (até 414px) */
@media (max-width: 414px) {
    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .logo-and-content {
        flex: 1;
    }

    .logo {
        margin-bottom: 20px;
        margin-top: 9px;
    }

    .logo-img {
        margin-top: -32px;
        margin-left: 8px;
        width: 103px;
        height: 32px;
        margin-bottom: 222px;
    }

    .main-content {
        text-align: left;
        max-width: 100%;
        margin-top: 266px;
        margin-left: -9px;
    }

    .get-started {
        font-size: 10px;
        font-weight: bold;
        color: #B0B0B0;
        margin-bottom: 10px;
        font-family: Arial;
        position: relative;
        top: -226px;
    }

    .enjoy-rewards {
        font-size: 14px;
        margin-top: -6px;
        font-weight: 700;
        font-family: 'Josefin Sans', sans-serif;
    }

    .main-content h2 {
        font-size: 17px;
        font-weight: bold;
        width: 178px;
        height: 49px;
        margin-bottom: 1px;
        font-family: 'Josefin Sans', sans-serif;
        position: relative;
        top: -233px;
        margin-left: 20px;
    }

    .main-content p {
        font-size: 10px;
        margin-top: -215px;
        font-Weight: 700;
        margin-left: 20px;
        margin-bottom: 3px;
    }

    .cta-button {
        background-color: #494C54;
        color: #fff;
        border: none;
        padding: 5px 10px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        margin-left: 20px;
        font-family: Arial;
        margin-top: 9px;
    }

    .cta-button:hover {
        background-color: #333;
    }

    .card-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 50px;
    }

    .nav {
        position: absolute;
        top: 5px;
        right: 6px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        margin-left: -18px;
    }

    .login-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 2px 7px;
        font-size: 12px;
        cursor: pointer;
        border-radius: 5px;
        margin-left: -47px;
        font-family: Arial;
    }

    .signup-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 6px 5px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 5px;
        font-family: Arial;
    }

    .signup-button {
        background-color: #000;
        color: #fff;
    }

    .signup-button:hover {
        background-color: #333;
    }

    .card-image img {
        width: 86px;
        height: 215px;
        z-index: 2;
        margin-top: 39px;
        margin-left: -3px;
        margin-right: 4px;
        margin-bottom: 78px;
    }


    .confetti-item {
        position: absolute;
        top: 60px;
        left: -73px;
        width: 166px;
        height: 149px;
        z-index: 1;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

/* Resolução para smartphones Android grandes e tablets menores - Galaxy Note (até 412px) */
@media (max-width: 412px) {
    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .logo-and-content {
        flex: 1;
    }

    .logo {
        margin-bottom: 20px;
        margin-top: 9px;
    }

    .logo-img {
        margin-top: -32px;
        margin-left: 8px;
        width: 103px;
        height: 32px;
        margin-bottom: 222px;
    }

    .main-content {
        text-align: left;
        max-width: 100%;
        margin-top: 266px;
        margin-left: -9px;
    }

    .get-started {
        font-size: 10px;
        font-weight: bold;
        color: #B0B0B0;
        margin-bottom: 10px;
        font-family: Arial;
        position: relative;
        top: -226px;
    }

    .enjoy-rewards {
        font-size: 14px;
        margin-top: -6px;
        font-weight: 700;
        font-family: 'Josefin Sans', sans-serif;
    }

    .main-content h2 {
        font-size: 17px;
        font-weight: bold;
        width: 178px;
        height: 49px;
        margin-bottom: 1px;
        font-family: 'Josefin Sans', sans-serif;
        position: relative;
        top: -233px;
        margin-left: 20px;
    }

    .main-content p {
        font-size: 10px;
        margin-top: -215px;
        font-Weight: 700;
        margin-left: 20px;
        margin-bottom: 3px;
    }

    .cta-button {
        background-color: #494C54;
        color: #fff;
        border: none;
        padding: 5px 10px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        margin-left: 20px;
        font-family: Arial;
        margin-top: 9px;
    }

    .cta-button:hover {
        background-color: #333;
    }

    .card-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 50px;
    }

    .nav {
        position: absolute;
        top: 5px;
        right: 6px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        margin-left: -18px;
    }

    .login-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 2px 7px;
        font-size: 12px;
        cursor: pointer;
        border-radius: 5px;
        margin-left: -47px;
        font-family: Arial;
    }

    .signup-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 6px 5px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 5px;
        font-family: Arial;
    }

    .signup-button {
        background-color: #000;
        color: #fff;
    }

    .signup-button:hover {
        background-color: #333;
    }

    .card-image img {
        width: 86px;
        height: 215px;
        z-index: 2;
        margin-top: 39px;
        margin-left: -3px;
        margin-right: 4px;
        margin-bottom: 78px;
    }


    .confetti-item {
        position: absolute;
        top: 60px;
        left: -73px;
        width: 166px;
        height: 149px;
        z-index: 1;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

/* Resolução para smartphones maiores - iPhone 12, iPhone 13 (até 390px) */
@media (max-width: 390px) {
    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .logo-and-content {
        flex: 1;
    }

    .logo {
        margin-bottom: 20px;
        margin-top: 9px;
    }

    .logo-img {
        margin-top: -32px;
        margin-left: 8px;
        width: 103px;
        height: 32px;
        margin-bottom: 222px;
    }

    .main-content {
        text-align: left;
        max-width: 100%;
        margin-top: 266px;
        margin-left: -9px;
    }

    .get-started {
        font-size: 10px;
        font-weight: bold;
        color: #B0B0B0;
        margin-bottom: 10px;
        font-family: Arial;
        position: relative;
        top: -226px;
    }

    .enjoy-rewards {
        font-size: 14px;
        margin-top: -6px;
        font-weight: 700;
        font-family: 'Josefin Sans', sans-serif;
    }

    .main-content h2 {
        font-size: 17px;
        font-weight: bold;
        width: 178px;
        height: 49px;
        margin-bottom: 1px;
        font-family: 'Josefin Sans', sans-serif;
        position: relative;
        top: -233px;
        margin-left: 20px;
    }

    .main-content p {
        font-size: 10px;
        margin-top: -215px;
        font-Weight: 700;
        margin-left: 20px;
        margin-bottom: 3px;
    }

    .cta-button {
        background-color: #494C54;
        color: #fff;
        border: none;
        padding: 5px 10px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        margin-left: 20px;
        font-family: Arial;
        margin-top: 9px;
    }

    .cta-button:hover {
        background-color: #333;
    }

    .card-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 50px;
    }

    .nav {
        position: absolute;
        top: 5px;
        right: 6px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        margin-left: -18px;
    }

    .login-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 2px 7px;
        font-size: 12px;
        cursor: pointer;
        border-radius: 5px;
        margin-left: -47px;
        font-family: Arial;
    }

    .signup-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 6px 5px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 5px;
        font-family: Arial;
    }

    .signup-button {
        background-color: #000;
        color: #fff;
    }

    .signup-button:hover {
        background-color: #333;
    }

    .card-image img {
        width: 86px;
        height: 215px;
        z-index: 2;
        margin-top: 39px;
        margin-left: -3px;
        margin-right: 4px;
        margin-bottom: 78px;
    }


    .confetti-item {
        position: absolute;
        top: 60px;
        left: -73px;
        width: 166px;
        height: 149px;
        z-index: 1;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

/* Resolução para smartphones pequenos - iPhone SE, iPhone 6/7/8, Android (até 375px) */
@media (max-width: 375px) {
    .container {
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .logo-and-content {
        flex: 1;
    }

    .logo {
        margin-bottom: 20px;
        margin-top: 9px;
    }

    .logo-img {
        margin-top: -32px;
        margin-left: 8px;
        width: 103px;
        height: 32px;
        margin-bottom: 222px;
    }

    .main-content {
        text-align: left;
        max-width: 100%;
        margin-top: 266px;
        margin-left: -9px;
    }

    .get-started {
        font-size: 10px;
        font-weight: bold;
        color: #B0B0B0;
        margin-bottom: 10px;
        font-family: Arial;
        position: relative;
        top: -226px;
    }

    .enjoy-rewards {
        font-size: 14px;
        margin-top: -6px;
        font-weight: 700;
        font-family: 'Josefin Sans', sans-serif;
    }

    .main-content h2 {
        font-size: 17px;
        font-weight: bold;
        width: 178px;
        height: 49px;
        margin-bottom: 1px;
        font-family: 'Josefin Sans', sans-serif;
        position: relative;
        top: -233px;
        margin-left: 20px;
    }

    .main-content p {
        font-size: 10px;
        margin-top: -215px;
        font-Weight: 700;
        margin-left: 20px;
        margin-bottom: 3px;
    }

    .cta-button[data-v-61dd7a3d] {
        background-color: #494C54;
        color: #fff;
        border: none;
        padding: 5px 10px;
        font-size: 8px;
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        margin-left: 20px;
        font-family: Arial;
        margin-top: 9px;
        Width: 91.04px;
        Height: 25.8px;
    }

    .cta-button:hover {
        background-color: #333;
    }

    .card-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 50px;
    }

    .nav {
        position: absolute;
        top: 5px;
        right: 6px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        margin-left: -18px;
    }

    .login-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 2px 7px;
        font-size: 12px;
        cursor: pointer;
        border-radius: 5px;
        margin-left: -47px;
        font-family: Arial;
    }

    .signup-button {
        background-color: transparent;
        color: #000;
        border: none;
        padding: 6px 5px;
        font-size: 9px;
        cursor: pointer;
        border-radius: 5px;
        font-family: Arial;
    }

    .signup-button {
        background-color: #000;
        color: #fff;
    }

    .signup-button:hover {
        background-color: #333;
    }

    .card-image img {
        width: 86px;
        height: 215px;
        z-index: 2;
        margin-top: 39px;
        margin-left: -3px;
        margin-right: 4px;
        margin-bottom: 78px;
    }


    .confetti-item {
        position: absolute;
        top: 60px;
        left: -73px;
        width: 166px;
        height: 149px;
        z-index: 1;
        -o-object-fit: cover;
        object-fit: cover;
    }
}
</style>